<template>
  <div class="subNav">
    <div class="item"> <router-link class="career" to="/career">生涯资讯</router-link>  <span class="more">更多>></span></div>
    <div class="item">
      <router-link class="items" v-for="item in navList" :key="item" :to="item.url">{{item.title}}</router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/首页/轮播/1-1.png"),
        require("@/assets/首页/轮播/图片2.png"),
        require("@/assets/首页/轮播/图片1.png"),
      ],
      navList:[
        {title:"师资培训",url:'/newsList'},
        {title:"基地学校",url:'/newsList'},
        {title:"研学营",url:'/newsList'},
        {title:"智慧课堂",url:'/newsList'},
        {title:"生涯咨询",url:'/newsList'},
        {title:"云平台",url:'/home'},
      ]
    };
  },
});
</script>
<style lang='scss' scoped>
.subNav {
  width: 1200px;
  .item {
    width: 1200px;
    height: 30px;
    margin-bottom: 12px;
    // background-color: blue;
    font-size: 26px;
    .career{
      margin-left: 24px;
    }
    .items{
      margin-right: 60px;
        // background-color: skyblue;
      width: 150px;
      text-align: center;
      display: inline-block;
      &:nth-last-child(1){
        margin-right: 0;
        // background-color: skyblue;
      }
    }
  }
  .more{
    float: right;
    margin-right: 10px;
  }
}
</style>
