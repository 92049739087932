
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/名师讲堂/名师讲堂.jpg"),
      ],
    };
  },
  methods:{
    goXSY(){
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    }
  }
});
