<template>
  <div>
    <div class="box">
      <div class="boxHead">
        <span>文章列表 | 共有{{list.length}}篇文章</span>
        <!-- <span class="more">更多>></span> -->
      </div>
      <div class="boxMain">
        <div class="item" v-for="item in list" :key="item">
          <img class="img" :src="item.img" alt="" />
          <div class="right">
            <div class="top">{{ item.title }}</div>
            <div class="middle">
              {{ item.content.slice(0, 10) }}...
              <span @click="goDetail(item.id)" class="read" >[阅读全文]</span>
            </div>
            <div class="bottom">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination @current-change="change"  page-size="4" background layout="prev, pager, next" :total="list.length">
      </el-pagination>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "middleBox",
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          id:1,
          content:
            "这是什么新闻这是什么新闻这是什么新闻这是什么新闻这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:2,
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:3,
          title: "新闻3",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:4,
          title: "新闻4",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:5,
          title: "新闻4",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:6,
          title: "新闻4",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:7,
          title: "新闻4",
          content: "这是什么新闻",
        },
         {
          img: require("@/assets/首页/联系我们1.png"),
          id:8,
          title: "新闻4",
          content: "这是什么新闻",
        },
      ],
        totalPage: 0,
    };
  },
  methods:{
    change(val:any){
       console.log(`当前页: ${val}`);
    },
    goDetail(id: number){
      console.log(id);
      this.$router.push({name:"newsDetail",params:{'id':id}})
    },
  }
});
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/mixin.scss";
.pagination {
  width: 500px;
  display: block;
  margin: 40px auto;
}
.box {
  width: 916px;
  max-height: 780px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
  .boxHead {
    height: 40px;
    width: 916px;
    background-color: #f5f5f5;
    // border-radius: 10px;
    border-bottom: 1px solid #dddddd;
    font-size: 16px;
    color: #333;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 16px;
    .more {
      float: right;
    }
  }
  .boxMain {
    height: 100%;
    // max-height: 760px;
    .item {
      width: 831px;
      height: 160px;
      margin-left: 55px;
      margin-top: 25px;
      display: inline-block;
      border-bottom: 1px solid #999;
    }
    .img {
      width: 130px;
      height: 130px;
      margin-right: 10px;
    }
    .right {
      display: inline-block;
      width: 620px;
      height: 90px;
      // background-color:pink;
      margin-bottom: 20px;
      position: relative;
      .top {
        position: absolute;
        top: 0;
        font-size: 16px;
        color: #337ab7;
        font-size: 24px;
      }
      .middle {
        position: absolute;
        width: 720px;
        @include ellipsis;
        bottom: 32px;
        font-size: 12px;
        .read {
          color: #337ab7;
        }
      }
      .bottom {
        position: absolute;
        width: 720px;
        @include ellipsis;
        bottom: 0;
        font-size: 12px;
      }
    }
  }
}
</style>
