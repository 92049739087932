
import { defineComponent } from "vue";
export default defineComponent({
  name: "longBox",
  data() {
    return {
      list: [
        { img: require("@/assets/首页/联系我们1.png"), title: "公司新闻", content: "这是什么新闻aaaaaaaaaa",length:'4' },
        { img: require("@/assets/首页/联系我们1.png"), title: "行业动态", content: "这是什么新闻",length:'3' },

      ],
    };
  },
});
