
import { defineComponent } from "vue";
export default defineComponent({
  name: "middleBox",
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          id:1,
          content:
            "这是什么新闻这是什么新闻这是什么新闻这是什么新闻这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:2,
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:3,
          title: "新闻3",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:4,
          title: "新闻4",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:5,
          title: "新闻4",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:6,
          title: "新闻4",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          id:7,
          title: "新闻4",
          content: "这是什么新闻",
        },
         {
          img: require("@/assets/首页/联系我们1.png"),
          id:8,
          title: "新闻4",
          content: "这是什么新闻",
        },
      ],
        totalPage: 0,
    };
  },
  methods:{
    change(val:any){
       console.log(`当前页: ${val}`);
    },
    goDetail(id: number){
      console.log(id);
      this.$router.push({name:"newsDetail",params:{'id':id}})
    },
  }
});
