
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/首页/轮播/1-1.png"),
        require("@/assets/首页/轮播/图片2.png"),
        require("@/assets/首页/轮播/图片1.png"),
      ],
      navList:[
        {title:"师资培训",url:'/newsList'},
        {title:"基地学校",url:'/newsList'},
        {title:"研学营",url:'/newsList'},
        {title:"智慧课堂",url:'/newsList'},
        {title:"生涯咨询",url:'/newsList'},
        {title:"云平台",url:'/home'},
      ]
    };
  },
});
