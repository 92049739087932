<template>
  <div class="box">
    <div class="boxHead">
      <span>文章类型</span>
      <!-- <span class="more">更多>></span> -->
    </div>
    <div class="boxMain">
      <div class="item" v-for="item in list" :key="item">
          <a href="#">{{item.title}} ({{item.length}})</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "longBox",
  data() {
    return {
      list: [
        { img: require("@/assets/首页/联系我们1.png"), title: "公司新闻", content: "这是什么新闻aaaaaaaaaa",length:'4' },
        { img: require("@/assets/首页/联系我们1.png"), title: "行业动态", content: "这是什么新闻",length:'3' },

      ],
    };
  },
});
</script>
<style lang='scss' scoped>
  @import '~@/assets/styles/mixin.scss';
.box {
  width: 270px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
  .boxHead {
    height: 40px;
    width: 270px;
    background-color: #f5f5f5;
    // border-radius: 10px;
    border-bottom: 1px solid #dddddd;
    font-size: 16px;
    color: #333;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 16px;
    margin-bottom: 20px;
    .more {
      float: right;
    }
  }
  .boxMain {
    height: 100%;
    padding-bottom: 30px;
    .item{
      width: 220px;
      height: 14px;
      margin-left: 35px;
      margin-top: 5px;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      color: #337ab7;
    }
  
  }
}
</style>
